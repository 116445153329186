import { useState, useEffect } from "react";
import "bulma/css/bulma.min.css";
import "./App.scss";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { decodeToken } from "react-jwt";

import Pinner from "./components/Pinner";
import LoginImage from "./assets/login.png";

function App() {
  const client_id =
    "257593760263-7dh765a2m298ohq3daqtj40obu4uqj0r.apps.googleusercontent.com";

  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const handleTokenChange = (token) => {
    if (token) {
      const myDecodedToken = decodeToken(token);
      console.log("> ", myDecodedToken);
      setUser(myDecodedToken);
      localStorage.setItem("AuthToken", token);
      setAuthenticated(true);
    } else {
      const localToken = localStorage.getItem("AuthToken");
      if (localToken) {
        setToken(localToken);
        setAuthenticated(true);
      }
    }
  };

  useEffect(() => {
    handleTokenChange(token);
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem("AuthToken");
    setToken(null);
    setAuthenticated(false);
  };

  return (
    <div className="block">
      <GoogleOAuthProvider clientId={client_id}>
        {!isAuthenticated && (
          <div className="login">
            <img src={LoginImage} alt="Login" className="login-img" />
            <span className="login-title">
              Приватний VIP фан-клуб пса Протона
            </span>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                setToken(credentialResponse.credential);
              }}
              onError={() => {
                alert("Login Failed");
              }}
            />
          </div>
        )}

        {isAuthenticated && (
          <div>
            <div className="header">
              <div className="header-title">
                <span className="header-title-bold">
                  Hey, {user?.given_name}!
                </span>{" "}
                ({user?.email}){" "}
              </div>
              <button
                className="button is-info is-outlined pl-5 pr-5"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>

            <Pinner></Pinner>
          </div>
        )}
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
