const Types = {
  TypeOne: "Type 1",
  TypeTwo: "Type 2",
  TypeThree: "Type 3",
  TypeFour: "Type 4",
  TypeFive: "Type 5",
  TypeSix: "Type 6",
  TypeSeven: "Type 7",
  TypeEight: "Type 8",
  TypeNine: "Type 9",
  TypeTen: "Type 10",
};

export default Types;
