import { useState, useEffect, useRef } from "react";

import ImageMarker, { Marker } from "react-image-marker";
import { toPng } from "html-to-image";
import ImageUploading from "react-images-uploading";

import "bulma/css/bulma.min.css";
import "../App.scss";
import Types from "../modules/types.enum";

function Pinner() {
  const elementRef = useRef(null);
  let [currentType, setMarkerType] = useState(Types.TypeOne);
  let [markers, setMarkers] = useState([]);
  let [imageWidth, setImageWidth] = useState(0);
  let [imageHeight, setImageHeight] = useState(0);
  let [imageName, setImageName] = useState("example.png");

  const [images, setImages] = useState([]);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    imageList.forEach((image) => {
      const name = image?.file?.name;
      const parsedName = name.split(".")[0] + "-pinned";
      setImageName(parsedName);
      const img = new Image();
      img.src = image.data_url;
      img.onload = () => {
        setImageWidth(img.naturalWidth);
        setImageHeight(img.naturalHeight);
      };
    });
    setImages(imageList);
  };

  const [markerCounts, setMarkerCounts] = useState(Array(10).fill(0));

  useEffect(() => {
    let newMarkerCounts = [...markerCounts];
    for (let i = 0; i < 10; i++) {
      newMarkerCounts[i] = document.getElementsByClassName(
        `marker-type${i + 1}`
      )?.length;
    }
    setMarkerCounts(newMarkerCounts);
  }, [markers, currentType]);

  const CustomMarker = (props) => {
    switch (props.type) {
      case Types.TypeOne:
        return <div className="marker marker-type1" data-testid="marker"></div>;
      case Types.TypeTwo:
        return <div className="marker marker-type2" data-testid="marker"></div>;
      case Types.TypeThree:
        return <div className="marker marker-type3" data-testid="marker"></div>;
      case Types.TypeFour:
        return <div className="marker marker-type4" data-testid="marker"></div>;
      case Types.TypeFive:
        return <div className="marker marker-type5" data-testid="marker"></div>;
      case Types.TypeSix:
        return <div className="marker marker-type6" data-testid="marker"></div>;
      case Types.TypeSeven:
        return <div className="marker marker-type7" data-testid="marker"></div>;
      case Types.TypeEight:
        return <div className="marker marker-type8" data-testid="marker"></div>;
      case Types.TypeNine:
        return <div className="marker marker-type9" data-testid="marker"></div>;
      case Types.TypeTen:
        return (
          <div className="marker marker-type10" data-testid="marker"></div>
        );
      default:
        return <div className="marker marker-type1" data-testid="marker"></div>;
    }
  };

  const htmlToImageConvert = () => {
    const imageMarkers = document.querySelectorAll(".image-marker");
    imageMarkers.forEach((marker) => {
      marker.style.width = "100%";
    });

    toPng(elementRef.current, {
      cacheBust: false,
      canvasWidth: imageWidth,
      canvasHeight: imageHeight,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = imageName + ".png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateMarker = (marker) => {
    marker.type = currentType;
    setMarkers((prev) => [...prev, marker]);
  };

  const handleClick = (newMarkerType) => {
    setMarkerType(newMarkerType);
  };

  return (
    <div className="block p-3 m-3">
      <div className="columns">
        <div className="column is-2">
          <div className="block mb-3 p-3 controls">
            <div className="buttons-block">
              <div className="is-size-4 has-text-light">Types: </div>

              <div className="divider"></div>
              <div className="radio-group">
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeOne"
                    name="type"
                    value={Types.TypeOne}
                    onClick={() => handleClick(Types.TypeOne)}
                    defaultChecked
                  />
                  <label htmlFor="typeOne">
                    <span className="type-prev type-prev-type1"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeOne}
                    />
                    <span>
                      (<span className="strong">{markerCounts[0]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeTwo"
                    name="type"
                    value={Types.TypeTwo}
                    onClick={() => handleClick(Types.TypeTwo)}
                  />
                  <label htmlFor="typeTwo">
                    <span className="type-prev type-prev-type2"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeTwo}
                    />
                    <span>
                      (<span className="strong">{markerCounts[1]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeThree"
                    name="type"
                    value={Types.TypeThree}
                    onClick={() => handleClick(Types.TypeThree)}
                  />
                  <label htmlFor="typeThree">
                    <span className="type-prev type-prev-type3"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeThree}
                    />
                    <span>
                      (<span className="strong">{markerCounts[2]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeFour"
                    name="type"
                    value={Types.TypeFour}
                    onClick={() => handleClick(Types.TypeFour)}
                  />
                  <label htmlFor="typeFour">
                    <span className="type-prev type-prev-type4"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeFour}
                    />
                    <span>
                      (<span className="strong">{markerCounts[3]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeFive"
                    name="type"
                    value={Types.TypeFive}
                    onClick={() => handleClick(Types.TypeFive)}
                  />
                  <label htmlFor="typeFive">
                    <span className="type-prev type-prev-type5"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeFive}
                    />
                    <span>
                      (<span className="strong">{markerCounts[4]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeSix"
                    name="type"
                    value={Types.TypeSix}
                    onClick={() => handleClick(Types.TypeSix)}
                  />
                  <label htmlFor="typeSix">
                    <span className="type-prev type-prev-type6"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeSix}
                    />
                    <span>
                      (<span className="strong">{markerCounts[5]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeSeven"
                    name="type"
                    value={Types.TypeSeven}
                    onClick={() => handleClick(Types.TypeSeven)}
                  />
                  <label htmlFor="typeSeven">
                    <span className="type-prev type-prev-type7"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeSeven}
                    />
                    <span>
                      (<span className="strong">{markerCounts[6]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeEight"
                    name="type"
                    value={Types.TypeEight}
                    onClick={() => handleClick(Types.TypeEight)}
                  />
                  <label htmlFor="typeEight">
                    <span className="type-prev type-prev-type8"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeEight}
                    />
                    <span>
                      (<span className="strong">{markerCounts[7]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeNine"
                    name="type"
                    value={Types.TypeNine}
                    onClick={() => handleClick(Types.TypeNine)}
                  />
                  <label htmlFor="typeNine">
                    <span className="type-prev type-prev-type9"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeNine}
                    />
                    <span>
                      (<span className="strong">{markerCounts[8]}</span>)
                    </span>
                  </label>
                </div>
                <div className="radio-group-item">
                  <input
                    type="radio"
                    id="typeTen"
                    name="type"
                    value={Types.TypeTen}
                    onClick={() => handleClick(Types.TypeTen)}
                  />
                  <label htmlFor="typeTen">
                    <span className="type-prev type-prev-type10"></span>
                    <input
                      className="input radio-input"
                      type="text"
                      placeholder={Types.TypeTen}
                    />
                    <span>
                      (<span className="strong">{markerCounts[9]}</span>)
                    </span>
                  </label>
                </div>
              </div>

              <div className="divider"></div>
              <div className="buttons">
                <div className="is-size-6 has-text-light">Markers: </div>
                <button
                  className="button is-dark pl-5 pr-5"
                  disabled={!markers.length > 0}
                  onClick={() => setMarkers((prev) => prev.slice(0, -1))}
                >
                  Undo
                </button>
                <button
                  className="button is-danger pl-5 pr-5"
                  disabled={!markers.length > 0}
                  onClick={() => setMarkers([])}
                >
                  Reset
                </button>
              </div>

              {images.length > 0 && (
                <div>
                  <div className="divider"></div>
                  <div className="buttons buttons-zoom">
                    <div className="is-size-6 has-text-light">Zoom: </div>
                    <button
                      className="button is-info is-outlined"
                      onClick={() => {
                        const imageMarkers =
                          document.querySelectorAll(".image-marker");
                        imageMarkers.forEach((marker) => {
                          marker.style.width = "100%";
                        });
                      }}
                    >
                      Reset
                    </button>

                    <button
                      className="button is-primary is-outlined"
                      onClick={() => {
                        const imageMarkers =
                          document.querySelectorAll(".image-marker");
                        imageMarkers.forEach((marker) => {
                          marker.style.width = "150%";
                        });
                      }}
                    >
                      x1.5
                    </button>

                    <button
                      className="button is-primary is-outlined"
                      onClick={() => {
                        const imageMarkers =
                          document.querySelectorAll(".image-marker");
                        imageMarkers.forEach((marker) => {
                          marker.style.width = "200%";
                        });
                      }}
                    >
                      x2
                    </button>

                    <button
                      className="button is-primary is-outlined"
                      onClick={() => {
                        const imageMarkers =
                          document.querySelectorAll(".image-marker");
                        imageMarkers.forEach((marker) => {
                          marker.style.width = "250%";
                        });
                      }}
                    >
                      x2.5
                    </button>

                    <button
                      className="button is-primary is-outlined"
                      onClick={() => {
                        const imageMarkers =
                          document.querySelectorAll(".image-marker");
                        imageMarkers.forEach((marker) => {
                          marker.style.width = "300%";
                        });
                      }}
                    >
                      x3
                    </button>

                    <button
                      className="button is-primary is-outlined"
                      onClick={() => {
                        const imageMarkers =
                          document.querySelectorAll(".image-marker");
                        imageMarkers.forEach((marker) => {
                          marker.style.width = "400%";
                        });
                      }}
                    >
                      x4
                    </button>

                    <button
                      className="button is-primary is-outlined"
                      onClick={() => {
                        const imageMarkers =
                          document.querySelectorAll(".image-marker");
                        imageMarkers.forEach((marker) => {
                          marker.style.width = "500%";
                        });
                      }}
                    >
                      x5
                    </button>
                  </div>
                </div>
              )}

              <div className="divider"></div>
              <div className="buttons">
                <button
                  className="button is-link is-fullwidth"
                  onClick={htmlToImageConvert}
                  disabled={!images.length > 0}
                >
                  Download Image
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-10">
          <ImageUploading
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            acceptType={["jpg", "jpeg", "png", "tiff"]}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              isDragging,
              dragProps,
            }) => (
              <div className="upload__image-wrapper">
                {imageList.length === 0 && (
                  <button
                    className="button is-info is-outlined is-fullwidth is-large"
                    style={isDragging ? { color: "#fff" } : null}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Click or Drop here
                  </button>
                )}

                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <div ref={elementRef} className="image-container">
                      <ImageMarker
                        src={image.data_url}
                        markers={markers}
                        onAddMarker={(marker) => generateMarker(marker)}
                        markerComponent={CustomMarker}
                      />
                    </div>
                    <button
                      className="button is-danger is-dark is-fullwidth mt-3 mb-3"
                      onClick={() => {
                        onImageRemoveAll();
                        setMarkers([]);
                      }}
                    >
                      DELETE Image
                    </button>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
      </div>
    </div>
  );
}

export default Pinner;
